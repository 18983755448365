export function useHotelSearch() {
  const dayjs = useDayjs()

  // 搜索条件, 存储在query中
  const checkInDate = useRouteQuery<string>('checkInDate')
  const checkOutDate = useRouteQuery<string>('checkOutDate')
  const cityName = useRouteQuery<string>('cityName')
  const cityId = useRouteQuery<string>('cityId')

  const childNum = useRouteQuery<string>('childNum')
  const adultNum = useRouteQuery<string>('adultNum')
  const roomNum = useRouteQuery<string>('roomNum')

  const searchParams = useState('hotelSearchParams', () => {
    return {
      selectCity: {
        cityId: cityId.value || '',
        cityName: cityName.value || '',
      },
      selectDate: [checkInDate.value ? dayjs(checkInDate.value).toDate() : dayjs().startOf('day').toDate(), checkOutDate.value ? dayjs(checkOutDate.value).toDate() : dayjs().startOf('day').add(1, 'day').toDate()],
      room: {
        roomNum: roomNum.value ? Number(roomNum.value) : 1,
        adultNum: adultNum.value ? Number(adultNum.value) : 1,
        childNum: childNum.value ? Number(childNum.value) : 0,
      },
    }
  })

  const formatSelectDate = computed(() => {
    return searchParams.value.selectDate.map(d => dayjs(d).format('MMM DD, ddd'))
  })

  // 计算入住天数
  const diffDays = computed(() => {
    if (searchParams.value.selectDate.length < 2)
      return '0'
    return dayjs(searchParams.value.selectDate[1]).diff(dayjs(searchParams.value.selectDate[0]), 'day')
  })

  // 弹窗控制
  const showCityPopup = useState('showHotelCityPopup', () => false)
  const showDatePopup = useState('showHoetelDatePopup', () => false)
  const showRoomPopup = useState('showHotelRoomPopup', () => false)

  function watchSearchParamsEffect() {
    watch(() => searchParams.value.selectDate, () => {
      checkInDate.value = dayjs(searchParams.value.selectDate[0]).format('YYYY-MM-DD')
      checkOutDate.value = dayjs(searchParams.value.selectDate[1]).format('YYYY-MM-DD')
    })

    watch(() => searchParams.value.selectCity, () => {
      cityName.value = searchParams.value.selectCity.cityName
      cityId.value = searchParams.value.selectCity.cityId
    })

    watch(() => searchParams.value.room, () => {
      roomNum.value = searchParams.value.room.roomNum.toString()
      adultNum.value = searchParams.value.room.adultNum.toString()
      childNum.value = searchParams.value.room.childNum.toString()
    })
  }

  return {
    diffDays,
    showCityPopup,
    showDatePopup,
    showRoomPopup,
    searchParams,
    formatSelectDate,
    watchSearchParamsEffect,
    checkInDate,
    checkOutDate,
    adultNum,
    childNum,
  }
}
